




















































































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiGoodsCommentAssistantAdd } from '@/api/goods'
import { timeFormat } from '@/utils/util'
import { apiUserLevelList } from '@/api/user/user'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddEvaluation extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    levelList: any[] = []
    form = {
        goods_id: '',
        goods_comment: 5,
        comment: '',
        avatar: '',
        nickname: '',
        level_id: '',
        comment_time: '',
        comment_images: ''
    }

    rules = {
        avatar: [
            {
                required: true,
                message: '请添加会员头像',
                trigger: ['blur', 'change']
            }
        ],
        nickname: [
            {
                required: true,
                message: '请输入用户昵称',
                trigger: ['blur', 'change']
            }
        ],
        level_id: [
            {
                required: true,
                message: '请选择会员等级',
                trigger: ['blur', 'change']
            }
        ],
        comment_time: [
            {
                required: true,
                message: '请选择评价时间',
                trigger: ['blur', 'change']
            }
        ],
        goods_comment: [
            {
                required: true,
                message: '请选择评价等级',
                trigger: ['blur', 'change']
            }
        ]
    }

    handleSave() {
        this.$refs.form.validate((valid: boolean, object: any) => {
            this.form.goods_id = this.$route.query.goods_id as string
            if (valid) {
                apiGoodsCommentAssistantAdd(this.form).then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }

    getUserLevel() {
        apiUserLevelList({
            page_type: 0
        }).then(res => {
            this.levelList = res.lists
        })
    }

    created() {
        this.getUserLevel()
    }
}
